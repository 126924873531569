import { useState } from 'react';
import './App.css';
import Confetti from 'react-confetti';

function App() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    // Simple email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      alert('Please enter a valid email address');
      return;
    }

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/emails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, selectedOption }),
      });

      setSubmitted(true);

    } catch (error) {
      console.error('Error submitting email:', error);
      alert('Failed to submit email. Please try again.');
    }
  };

  return (
    <div className="App">
      {submitted && <Confetti />}
      <h3>Outside IR35 Contract Job Board</h3>
      <p>Join the wait list now and find or post the best outside IR35 contract opportunities in one place.</p>
      <br />
      <h1>I want to...</h1>
      <div className="button-container">
        <button
          className={`action-button ${selectedOption === 'find' ? 'active' : ''}`}
          onClick={() => setSelectedOption('find')}
        >
          <h2>Find a Contract</h2>
          <p>Browse available opportunities</p>
        </button>
        <button
          className={`action-button ${selectedOption === 'post' ? 'active' : ''}`}
          onClick={() => setSelectedOption('post')}
        >
          <h2>Post a Contract</h2>
          <p>Advertise your contract opportunity</p>
        </button>
      </div>

      {selectedOption && (
        <div className={`form-container ${selectedOption ? 'form-visible' : ''}`}>
          {submitted ? (
            <h3>Email Submitted!</h3>
          ) : (
            <>
              {selectedOption === 'find' && <h3>Join the wait list now to receive new Outside IR35 roles soon</h3>}
              {selectedOption === 'post' && <h3>Join the wait list now to receive 5 free job posts</h3>}
              <div className="subscribe-form">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button className="subscribe-button" onClick={handleSubmit}>Subscribe</button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default App;